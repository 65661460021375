/* jshint esversion:6 */

import intervalToDuration from "date-fns/intervalToDuration";
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import { DOM_STRINGS } from "./dom-strings";

const count = function () {

    const interval = 1000; //1 second
    const date = new Date(new Date(2023, 10, 29, 8, 30, 0).toLocaleString("en-US", { timeZone: "America/La_Paz" }));

    const daysEl = document.getElementById(DOM_STRINGS.COUNTER_DAYS);
    const hoursEl = document.getElementById(DOM_STRINGS.COUNTER_HOURS);
    const minutesEl = document.getElementById(DOM_STRINGS.COUNTER_MINUTES);
    const secondsEl = document.getElementById(DOM_STRINGS.COUNTER_SECONDS);

    if (new Date() >= date) {
        daysEl.innerHTML = 0;
        hoursEl.innerHTML = 0;
        minutesEl.innerHTML = 0;
        secondsEl.innerHTML = 0;
        return;
    }

    setInterval(_ => {
        const now = new Date();
        const interval = intervalToDuration({
            start: now,
            end: date
        });
        interval.days = differenceInCalendarDays(date, now);

        daysEl.innerHTML = interval.days >= 0 ? interval.days : 0;
        hoursEl.innerHTML = interval.hours >= 0 ? interval.hours : 0;
        minutesEl.innerHTML = interval.minutes >= 0 ? interval.minutes : 0;
        secondsEl.innerHTML = interval.seconds >= 0 ? interval.seconds : 0;

    }, interval);

};

count();
