/*jshint esversion: 6*/
import {DOM_STRINGS} from "./dom-strings";

/**
 * 
 * @param {Element} tab 
 */
const showTab = function (tab) {

    const tabId = tab.getAttribute(DOM_STRINGS.TAB_LINKS_DATA_TAB);
    const targetTabContent = document.querySelector(tabId);

    tab.classList.add(DOM_STRINGS.ACTIVE_CLASS);
    targetTabContent.classList.add(DOM_STRINGS.ACTIVE_CLASS);
};
/**
 * 
 * @param {Element} tab 
 */
const hideActiveTab = function () {
    const activeTabLink = document.querySelector(DOM_STRINGS.ACTIVE_TAB_LINK);
    activeTabLink.classList.remove(DOM_STRINGS.ACTIVE_CLASS);

    const activeTab = document.querySelector(DOM_STRINGS.ACTIVE_TAB_CONTENT);
    activeTab.classList.remove(DOM_STRINGS.ACTIVE_CLASS);
};

/**
 * 
 */
const bindTabs = function () {
    const tabs = document.querySelectorAll(DOM_STRINGS.TAB_LINKS);
    tabs.forEach(tab => {
        tab.addEventListener("click", e => {
            hideActiveTab();
            showTab(e.target);


        });
    });

};

bindTabs();


