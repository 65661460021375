/*jshint esversion: 6*/

import "./tabs";
import "../css/main.css";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { faSpinner, faEnvelope, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";
import { faFacebook, faLinkedin, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import SmoothScroll from "smooth-scroll";
import "./counter";
import 'lazysizes'



library.add(faCalendarAlt, faFacebook, faLinkedin, faInstagram, faSpinner, faWhatsapp, faEnvelope, faLocationDot);
dom.watch();

document.addEventListener("DOMContentLoaded", function () {
    document.querySelector(".menu-mobile-icon").addEventListener("click", e => {
        document.getElementsByTagName("body")[0].classList.toggle("menu-shown");
    });
});

const scroll = new SmoothScroll('a[href*="#"]');


var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {

            panel.style.maxHeight = null;
            panel.style.padding = 0 + "px" + " " + 18 + "px";
        } else {
            panel.style.padding = 18 + "px" + " " + 18 + "px";
            panel.style.maxHeight = panel.scrollHeight + "px";

        }
    });
}

//Initialize Tooltip



(function () {

    const html = `<div class="mb-1"><strong>Hora local por país</strong></div>
<ul>
    <li>DF, México: 5:00 PM</li>
    <li>Lima, Perú: 6:00 PM</li>
    <li>San José, Costa Rica: 5:00 PM</li>
    <li>Santiago, Chile: 7:00 PM</li>
    <li>Bogotá, Colombia: 6:00 PM</li>
    <li>Buenos Aires, Argentina: 8:00 PM</li>
</ul>`;

    Array.from(document.querySelectorAll(".event-date .tooltip")).forEach(el => {
        const tooltipText = document.createElement("div");
        tooltipText.setAttribute("class", "tooltip-text");
        tooltipText.innerHTML = html;
        el.appendChild(tooltipText);
    });

})();


// (function () {

//     axios.get("/api/course/"+registration.getData().courseId)
//         .then(results => {
//             //console.log(results);
//             registration.initialize({
//                 price: {
//                     DOP: results.data.priceDOP,
//                     USD: results.data.priceUSD,
//                 }
//             });

//         }, results => {
//             //console.error(results);
//             showError("Ha ocurrido un error desconocido. Puede intentarlo de nuevo o bien comunicarse con nosotros a info@squalitas.com.");
//         });

// })();



// (function() {

//     axios.post("http://sqms.lan/api/event/enroll", {
//         contact: {
//             firstname: "Rafael",
//             lastname: "Mateo",
//             email: "rmateo32@squalitas.com",
//             source: "quality-tour",
//         },
//         currency: "USD",

//         company: {
//             name: "ABC",
//             rnc: "565656565",
//             country: "DO",
//             companyCat: "PRIV",
//             source: "quality-tour",

//         },
//         attendees: [
//             {
//                 firstname: "rafael",
//                 lastname: "mateo",
//                 email: "rmateo@squalqwita3saad.com",
//                 source: "quality-tour",
//             },
//             {
//                 firstname: "valery",
//                 lastname: "bobadilla",
//                 email: "ana_cristiadna24023786@hotmail.com",
//                 source: "quality-tour",
//             },
//             {
//                 firstname: "valery",
//                 lastname: "bobadilla 2",
//                 email: "rmateo@ad23asds2asdas.com",
//                 source: "quality-tour",
//             }
//         ],
//         service: 1202
//     });

// })();