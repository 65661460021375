/*jshint esversion: 6*/

export const DOM_STRINGS = {
    TAB_LINKS: ".tab-link",
    ACTIVE_TAB_LINK: ".tab-link.show",
    ACTIVE_TAB_CONTENT: ".tab-content.show",
    ACTIVE_CLASS: "show",
    TAB_LINKS_DATA_TAB: "data-tab",
    PAYPAL_BTN: "#paypal-button-container",
    COUNTER_DAYS: "counter-days",
    COUNTER_MINUTES: "counter-minutes",
    COUNTER_HOURS: "counter-hours",
    COUNTER_SECONDS: "counter-seconds",

    SECTIONS: {
        FORM_SECTION: "registration",
        REGISTRATION_SUCCESS: "registration-success",
    },

    ALERT: {
        SUCCESS_MESSAGE: "success-message",
        ERROR_MESSAGE: "error-message",
    },

    FORM: {
        ATTENDEE_FIELDS: "attendee-fields",
        ADD_ATTENDEE_BTN: "add-participant",
        SIGN_UP: {
            FIELDS: {
                FIRSTNAME: "firstname",
                LASTNAME: "lastname",
                EMAIL: "email",
            },
            LABELS: {
                FIRSTNAME: "Nombre",
                LASTNAME: "Apellido",
                EMAIL: "Correo",
            }
        },

        BILLING: {
            COUNTRY: "country",
            INVOICE_TYPE: "invoice-type",
            CURRENCY: "currency",
            AMOUNT: "amount",
            BILL_TO_COMPANY: "bill-to-company",
            COMPANY: {
                NAME: "company",
                RNC: "rnc",
                FISCAL_REGIME: "invoice-type"
            },
            CONTACT: {
                FIRSTNAME: "contact-firstname",
                LASTNAME: "contact-lastname",
                EMAIL: "contact-email",
            }
        }

    }
};

export const FIELDS  = {
    //CURRENCY: document.getElementById(DOM_STRINGS.FORM.BILLING.CURRENCY),
    BILLING: {
        
        BILL_TO_COMPANY: document.getElementById(DOM_STRINGS.FORM.BILLING.BILL_TO_COMPANY),
        COMPANY: {
            NAME: document.getElementById(DOM_STRINGS.FORM.BILLING.COMPANY.NAME),
            RNC: document.getElementById(DOM_STRINGS.FORM.BILLING.COMPANY.RNC),
            FISCAL_REGIME: document.getElementById(DOM_STRINGS.FORM.BILLING.COMPANY.FISCAL_REGIME),
            COUNTRY: document.getElementById(DOM_STRINGS.FORM.BILLING.COUNTRY),
          
        },
        CONTACT: {
            FIRSTNAME: document.getElementById(DOM_STRINGS.FORM.BILLING.CONTACT.FIRSTNAME),
            LASTNAME: document.getElementById(DOM_STRINGS.FORM.BILLING.CONTACT.LASTNAME),
            EMAIL: document.getElementById(DOM_STRINGS.FORM.BILLING.CONTACT.EMAIL),
        }
    },
    AMOUNT: document.getElementById(DOM_STRINGS.FORM.BILLING.AMOUNT),
    INVOICE_TYPE: document.getElementById(DOM_STRINGS.FORM.BILLING.INVOICE_TYPE),
    ADD_ATTENDEE_BTN: document.getElementById(DOM_STRINGS.FORM.ADD_ATTENDEE_BTN),

};

FIELDS.BILLING.COMPANY.COMPANYCAT = FIELDS.BILLING.COMPANY.FISCAL_REGIME;

export const ELEMENTS = {
    LOADING: document.querySelector(".form-container .loading"),
    SUCCESS_MESSAGE: document.getElementById(DOM_STRINGS.ALERT.SUCCESS_MESSAGE),
    ERROR_MESSAGE: document.getElementById(DOM_STRINGS.ALERT.ERROR_MESSAGE),
    FORM_SECTION: document.getElementById(DOM_STRINGS.SECTIONS.FORM_SECTION),
    REGISTRATION_SUCCESS: document.getElementById(DOM_STRINGS.SECTIONS.REGISTRATION_SUCCESS),
};
